<template>
  <div class="pt-2">
    <div v-if="ProgressBarValue && ProgressBarValue.showProgressbar">
      <!-- Line ProgressBar -->
      <div
        v-if="
          ProgressBarValue.progressBarType === 'line' &&
          ProgressBarValue.progressBarVariant == 'v1'
        "
      >
        <div
          class="progress"
          :style="
            getProgressBarBorderRadius(ProgressBarValue.progressStyles) +
            getProgressBarHeight(ProgressBarValue.progressStyles) +
            getLineProgressControlBg(ProgressBarValue.progressStyles) +
            getLineProgressControlOpacity(ProgressBarValue.progressStyles) +
            getProgressContainerDirection()
          "
        >
          <div
            class="progress-bar"
            role="progressbar"
            :style="
              getLineProgressBarBg(ProgressBarValue.progressStyles) +
              getProgressBarBorderRadius(ProgressBarValue.progressStyles) +
              getLineProgressBarOpacity(ProgressBarValue.progressStyles) +
              getProgressDirection(ProgressBarValue) +
              `width:${calcWidth}%`
            "
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div
          class="my-2"
          :style="getProgressQuestionTextAlign(ProgressBarValue.progressStyles)"
        >
          <span
            v-if="ProgressBarValue.questionText"
            class="ml-1 font-weight-medium"
            :style="getProgressTextColor(ProgressBarValue.progressStyles)"
            >{{ ProgressBarValue.questionText }} {{ PageOrder(order) }}</span
          >
        </div>
      </div>

      <!-- Dots ProgressBar -->
      <div
        v-else-if="
          ProgressBarValue.progressBarType === 'dots' &&
          ProgressBarValue.progressBarVariant == 'v1'
        "
      >
        <div
          class="dotsProgress d-flex align-items-center flex-wrap"
          :class="[
            {
              'justify-content-center': centerAlignProgressBar,
            },
            isRTLDirection(ProgressBarValue) ? 'mr-3 flex-row-reverse' : 'ml-3',
          ]"
        >
          <div
            class="dots mr-2 mb-1"
            v-for="(dot, index) in totalPagesLength"
            :key="index"
            :style="
              order == getQuestionPagesArray[index].order
                ? getLineProgressBarBg(ProgressBarValue.progressStyles) +
                  getLineProgressBarOpacity(ProgressBarValue.progressStyles) +
                  getDotsHeightWidth(ProgressBarValue.progressStyles)
                : getLineProgressControlBg(ProgressBarValue.progressStyles) +
                  getLineProgressControlOpacity(
                    ProgressBarValue.progressStyles
                  ) +
                  getDotsHeightWidth(ProgressBarValue.progressStyles)
            "
          ></div>

          <span
            v-if="ProgressBarValue.questionText"
            :class="isRTLDirection(ProgressBarValue) ? 'mr-3 ' : 'ml-3'"
            class="font-weight-medium"
            :style="getProgressTextColor(ProgressBarValue.progressStyles)"
            >{{ ProgressBarValue.questionText }} {{ PageOrder(order) }}
          </span>
        </div>
      </div>
      <!-- Check Progressbar -->
      <div
        class=""
        v-else-if="
          ProgressBarValue.progressBarType === 'dots' &&
          ProgressBarValue.progressBarVariant == 'v2'
        "
      >
        <div
          class="dotsProgress d-flex align-items-center flex-wrap"
          :class="[
            {
              'justify-content-center': centerAlignProgressBar,
            },
            isRTLDirection(ProgressBarValue) ? 'mr-3 flex-row-reverse' : 'ml-3',
          ]"
        >
          <i
            v-for="(dot, index) in totalPagesLength"
            :key="index"
            class="mr-2 mb-1 fa-solid fa-circle-check"
            :style="
              order >= getQuestionPagesArray[index].order
                ? getCheckFontSize(ProgressBarValue.progressStyles) +
                  getLineProgressBarCheckColor(
                    ProgressBarValue.progressStyles
                  ) +
                  getLineProgressBarOpacity(ProgressBarValue.progressStyles) +
                  getDotsHeightWidth(ProgressBarValue.progressStyles)
                : getLineProgressCheckControlBg(
                    ProgressBarValue.progressStyles
                  ) +
                  getLineProgressControlOpacity(
                    ProgressBarValue.progressStyles
                  ) +
                  getCheckFontSize(ProgressBarValue.progressStyles) +
                  getDotsHeightWidth(ProgressBarValue.progressStyles)
            "
          ></i>

          <span
            v-if="ProgressBarValue.questionText"
            :class="isRTLDirection(ProgressBarValue) ? 'mr-3 ' : 'ml-3'"
            class="font-weight-medium"
            :style="getProgressTextColor(ProgressBarValue.progressStyles)"
            >{{ ProgressBarValue.questionText }} {{ PageOrder(order) }}
          </span>
        </div>
      </div>

      <!-- circle and number progressbar -->
      <div
        class=""
        v-else-if="
          ProgressBarValue.progressBarType === 'line' &&
          (ProgressBarValue.progressBarVariant == 'v2' ||
            ProgressBarValue.progressBarVariant == 'v3')
        "
      >
        <div
          style="transition: 0.5 ease all"
          class="dotsProgress d-flex align-items-center justify-content-between w-100"
          :class="isRTLDirection(ProgressBarValue) ? 'flex-row-reverse' : ''"
        >
          <div
            v-for="(dot, index) in totalPagesLength"
            :key="index"
            :class="[getQuestionPagesArray.length != index + 1 ? 'w-100' : '']"
          >
            <div
              class="d-flex align-items-center position-relative"
              :class="
                isRTLDirection(ProgressBarValue) ? 'flex-row-reverse' : ''
              "
            >
              <div
                v-if="getQuestionPagesArray.length != index + 1"
                class="progress-bar-line"
                style="position: absolute; z-index: -1; margin-left: 0.3rem"
                :style="
                  order >= getQuestionPagesArray[index].order + 1
                    ? {
                        background: ProgressBarValue.progressStyles.LineBgColor,
                        width:
                          ProgressBarValue.progressStyles.lineHeight > 10
                            ? ProgressBarValue.progressStyles.lineHeight -
                              10 +
                              100 +
                              '%'
                            : '100%',
                        height:
                          ProgressBarValue.progressStyles.lineHeight + 'px',
                      }
                    : {
                        background:
                          ProgressBarValue.progressStyles.LineControlBg,
                        width:
                          ProgressBarValue.progressStyles.lineHeight > 10
                            ? ProgressBarValue.progressStyles.lineHeight -
                              10 +
                              100 +
                              '%'
                            : '100%',
                        height:
                          ProgressBarValue.progressStyles.lineHeight + 'px',
                      }
                "
              ></div>
              <div
                class="bubble-counter d-flex justify-content-center align-items-center"
                style="border-radius: 50%; color: white"
                :style="
                  order >= getQuestionPagesArray[index].order
                    ? {
                        background: ProgressBarValue.progressStyles.LineBgColor,
                        Opacity: ProgressBarValue.progressStyles.opacity
                          ? ProgressBarValue.progressStyles.opacity
                          : '100%',
                        height:
                          ProgressBarValue.progressStyles.lineHeight + 'px',
                        width:
                          ProgressBarValue.progressStyles.lineHeight + 'px',
                      }
                    : {
                        background:
                          ProgressBarValue.progressStyles.LineControlBg,
                        Opacity: ProgressBarValue.progressStyles.opacity
                          ? ProgressBarValue.progressStyles.opacity
                          : '100%',
                        height:
                          ProgressBarValue.progressStyles.lineHeight + 'px',
                        width:
                          ProgressBarValue.progressStyles.lineHeight + 'px',
                      }
                "
              >
                <div
                  v-if="ProgressBarValue.progressBarVariant == 'v2'"
                  :style="{
                    height:
                      ProgressBarValue.progressStyles.lineHeight / 2 + 'px',
                    width:
                      ProgressBarValue.progressStyles.lineHeight / 2 + 'px',
                    borderRadius: '50%',
                    background: 'white',
                  }"
                ></div>
                <span
                  v-else
                  :style="
                    CalcFontSize(ProgressBarValue.progressStyles.lineHeight)
                  "
                >
                  {{ index + 1 }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="my-2"
          :style="getProgressQuestionTextAlign(ProgressBarValue.progressStyles)"
        >
          <span
            v-if="ProgressBarValue.questionText"
            class="ml-1 font-weight-medium"
            :style="getProgressTextColor(ProgressBarValue.progressStyles)"
            >{{ ProgressBarValue.questionText }} {{ PageOrder(order) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cssFunctionMixin from "../customize/mixin/cssFunction";
export default {
  mixins: [cssFunctionMixin],
  props: ["order"],
  computed: {
    ...mapGetters([
      "getPreviewProgressBar",
      "getTotalPreviewPages",
      "getSelectedPage",
      "getPreviewSettings",
    ]),
    ProgressBarValue() {
      return this.getPreviewProgressBar;
    },
    isBearWithBenefitsClient() {
      return this.getPreviewSettings?.quizId
        ? process.env.VUE_APP_BEARWBENEFITS_QUIZID.includes(
            this.getPreviewSettings.quizId
          )
        : false;
    },
    barSize() {
      return "100%";
    },
    centerAlignProgressBar() {
      if (this.isBearWithBenefitsClient) {
        return this.getSelectedPage.pageDesign.imageBlock.src == "";
      }
      return false;
    },

    isGetStartedPage() {
      let count = 0;
      this.getTotalPreviewPages.forEach(
        (x) => x.value == "getStartedPage" && count++
      );

      return count >= 1 ? true : false;
    },
    totalPagesLength() {
      const allowedValues = this.isBearWithBenefitsClient
        ? ["singleAnswer", "multipleAnswer"]
        : ["singleAnswer", "multipleAnswer", "leadPage", "freeText"];

      return this.getTotalPreviewPages.reduce(
        (count, x) => (allowedValues.includes(x.value) ? count + 1 : count),
        0
      );
    },
    calcWidth() {
      const arr = this.getQuestionPagesArray;
      let progressWidth;

      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          if (
            this.getSelectedPage.value == arr[i].value &&
            this.getSelectedPage.order == arr[i].order
          ) {
            progressWidth = ((i + 1) / this.totalPagesLength) * 100;
          }
        }
      }
      return progressWidth;
    },
    dotOrder() {
      const arr = this.getQuestionPagesArray;
      let order;
      for (let i = 0; i < arr.length; i++) {
        if (
          this.getSelectedPage.value == arr[i].value &&
          this.getSelectedPage.order == arr[i].order &&
          this.order == arr[i].order
        ) {
          order = arr[i].order;
        }
      }
      return order;
    },
    getQuestionPagesArray() {
      const arr = [];
      const allowedValues = this.isBearWithBenefitsClient
        ? ["singleAnswer", "multipleAnswer"]
        : ["singleAnswer", "multipleAnswer", "leadPage", "freeText"];

      this.getTotalPreviewPages.forEach((x) => {
        if (allowedValues.includes(x.value)) {
          arr.push(x);
        }
      });

      return arr;
    },
  },
  methods: {
    CalcFontSize(size) {
      return `font-size:${size * 0.8}px; `;
    },
    PageOrder(order) {
      if (!this.isBearWithBenefitsClient) {
        if (this.isGetStartedPage) {
          return order;
        } else {
          return order + 1;
        }
      } else {
        const filteredPages = this.getQuestionPagesArray;
        const index = filteredPages.findIndex((page) => page.order === order);
        if (index !== -1) {
          return index + 1;
        }
        return null;
      }
    },
    isRTLDirection(property) {
      if (
        typeof property.RTLdirection !== "undefined" &&
        property.RTLdirection
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.dotsProgress .dots {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.progress-bar-line:first-child {
  border-radius: 12px;
}

.progress-bar-line {
  border-radius: 12px;
}
</style>
